import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function updateBacktestTradeChart(chartDiv, tradesData) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(chartDiv, am4charts.XYChart);

    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "selectX";

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    dateAxis.title.text = "Date";
    valueAxis.title.text = "Profit (%)";

    updateChartWithTradesResult(chart, tradesData)
}

function updateChartWithTradesResult(chart, tradesData) {
    // Add new column to the data to show the profit in percentage
    tradesData.forEach(function(item) {
        item.ReturnPct100 = (parseFloat(item.ReturnPct) * 100.0).toFixed(2);
    });

    // Data Arrays
    chart.data = tradesData;

    // Create the Equity Line Series
    var equityLine = chart.series.push(new am4charts.ColumnSeries());

    equityLine.name = "Profit (%)";
    equityLine.dataFields.dateX = "EntryTime";
    equityLine.dataFields.valueY = "ReturnPct100";
    equityLine.tooltipText = "Profit {valueY}%";
    equityLine.strokeWidth = 2;
    equityLine.minBulletDistance = 10;

    // Define colors for positive and negative bars
    equityLine.columns.template.adapter.add("fill", function (fill, target) {
        if (target.dataItem.valueY > 0) {
            return am4core.color("#17a2b8"); // Green for positive values
        } else {
            return am4core.color("#c41111"); // Red for negative values
        }
    });
}

export {updateBacktestTradeChart};