import React from "react";
import {Route, Switch} from "react-router-dom";

import {Footer, Loading, NavBar} from "./components";
import {ExternalApi, Home, Profile} from "./views";
import {withAuth0} from "@auth0/auth0-react";
import ProtectedRoute from "./auth/protected-route";

import "./app.css";
import Dashboard from "./views/dashboard";
import Portfolio from "./views/portfolio";

class App extends React.Component {
    render() {
        const {isLoading} = this.props.auth0;

        if (isLoading) {
            return <Loading/>;
        }

        return (
            <div id="app" className="container-fluid">
                <NavBar/>
                {/*<div className="container flex-grow-1">*/}
                <div className="mt-5">
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <ProtectedRoute path="/dashboard" exact component={Dashboard}/>
                        <ProtectedRoute path="/portfolio" exact component={Portfolio}/>
                        <ProtectedRoute path="/profile" component={Profile}/>
                        <ProtectedRoute path="/external-api" component={ExternalApi}/>
                    </Switch>
                </div>
                {/*</div>*/}
                <Footer/>
            </div>
        );
    }
}

export default withAuth0(App);
