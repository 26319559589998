import React, {useState} from "react";
import {Autocomplete} from "@material-ui/lab";
import {Button, debounce, TextField} from "@material-ui/core";

function SeasonalAnalysisBar({onChangeTickerHandler, onChangeTimeframeHandler, onAnalysisHandler}) {

    const [myOptions, setMyOptions] = useState([])

    function getTicker(searchTxt) {
        fetch(process.env.REACT_APP_API_URL + '/search?ticker=' + searchTxt)
            .then(response => response.json())
            .then(data => {
                let fetchTickers = [];
                for (var i = 0; i < data.quotes.length; i++) {
                    if (data.quotes[i].symbol !== undefined && data.quotes[i].symbol !== null) {
                        fetchTickers.push(data.quotes[i].longname + "(" + data.quotes[i].exchDisp + ") [" + data.quotes[i].symbol + "]");
                    }
                }
                setMyOptions(fetchTickers)
            });
    }

    const getTickerWithDebounce = debounce((searchTxt) => {
        getTicker(searchTxt)
    }, 300);

    const extractTicker = (selectedTicker) => {
        let extractedValue = "";
        if (selectedTicker !== null && selectedTicker !== undefined && selectedTicker !== "") {
            const regex = /\[([\d\D\w\W]+)\]/;
            const match = selectedTicker.match(regex);
            extractedValue = match ? match[1] : "";
        }
        return extractedValue;
    }

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div className="seasonality-analysis-form">
            {/*<div>*/}
            {/*    <Autocomplete*/}
            {/*        style={{width: 500, margin: "auto"}}*/}
            {/*        freeSolo*/}
            {/*        autoComplete*/}
            {/*        autoHighlight*/}
            {/*        options={myOptions}*/}
            {/*        onChange={(event, newInputValue) => {*/}
            {/*            onChangeTickerHandler(extractTicker(newInputValue));*/}
            {/*        }}*/}
            {/*        renderInput={(params) => (*/}
            {/*            <TextField*/}
            {/*                {...params}*/}
            {/*                onChange={(event) => {*/}
            {/*                    getTickerWithDebounce(event.target.value)*/}
            {/*                }}*/}
            {/*                variant="outlined"*/}
            {/*                label="Ticker"*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</div>*/}
            <select name="ticker" id="ticker" className="ticker"
                    onChange={element => onChangeTickerHandler(element.target.value)}>
                <option></option>
                <optgroup label="FTSEMIB" multiple="single">
                    <option value="FTSEMIB.MI">FTSE MIB INDEX</option>
                    <option value="A2A.MI">A2A</option>
                    <option value="AMP.MI">Amplifon</option>
                    <option value="G.MI">Assicurazioni Generali</option>
                    <option value="AZM.MI">Azimut</option>
                    <option value="BGN.MI">Banca Generali</option>
                    <option value="BMED.MI">Banca Mediolanum</option>
                    <option value="BAMI.MI">Banco Bpm</option>
                    <option value="BMPS.MI">Banca Mps</option>
                    <option value="BPE.MI">Bper Banca</option>
                    <option value="CPR.MI">Campari</option>
                    <option value="DIA.MI">DiaSorin</option>
                    <option value="ENEL.MI">Enel</option>
                    <option value="ENI.MI">Eni</option>
                    <option value="ERG.MI">Erg</option>
                    <option value="RACE.MI">Ferrari</option>
                    <option value="FBK.MI">FinecoBank</option>
                    <option value="HER.MI">Hera</option>
                    <option value="IP.MI">Interpump Group</option>
                    <option value="ISP.MI">Intesa Sanpaolo</option>
                    <option value="INW.MI">Inwit</option>
                    <option value="IG.MI">Italgas</option>
                    <option value="IVG.MI">Iveco</option>
                    <option value="LDO.MI">Leonardo</option>
                    <option value="MB.MI">Mediobanca</option>
                    <option value="MONC.MI">Moncler</option>
                    <option value="NEXI.MI">Nexi</option>
                    <option value="PIRC.MI">Pirelli & C</option>
                    <option value="PST.MI">Poste Italiane</option>
                    <option value="PRY.MI">Prysmian</option>
                    <option value="REC.MI">Recordati</option>
                    <option value="SPM.MI">Saipem</option>
                    <option value="SRG.MI">Snam Spa</option>
                    <option value="STLAM.MI">Stellantis N.V.</option>
                    <option value="STMMI.MI">STMicroelectronics N.V.</option>
                    <option value="TIT.MI">Telecom Italia</option>
                    <option value="TEN.MI">Tenaris</option>
                    <option value="TRN.MI">Terna</option>
                    <option value="UCG.MI">UniCredit</option>
                    <option value="UNI.MI">Unipol Gruppo</option>
                </optgroup>
                <optgroup label="DAX" multiple="single">
                    <option value="^GDAXI">DAX INDEX</option>
                    <option value="ADS.DE">Adidas</option>
                    <option value="ALV.DE">Allianz</option>
                    <option value="BAS.DE">BASF</option>
                    <option value="BAYN.DE">Bayer</option>
                    <option value="BMW.DE">Bayer</option>
                    <option value="BMW3.DE">Bayer</option>
                    <option value="BEI.DE">Beiersdorf</option>
                    <option value="BMW.DE">BMW</option>
                    <option value="BMW3.DE">BMW</option>
                    <option value="CBK.DE">Commerzbank</option>
                    <option value="CON.DE">Continental</option>
                    <option value="1COV.DE">Covestro</option>
                    <option value="DTG.DE">Daimler Truck Holding</option>
                    <option value="DBK.DE">Deutsche Bank</option>
                    <option value="DHL.DE">Deutsche Post</option>
                    <option value="DTE.DE">Deutsche Tel.</option>
                    <option value="EOAN.DE">E.ON</option>
                    <option value="FRE.DE">Fresenius SE</option>
                    <option value="HEN3.DE">Henkel</option>
                    <option value="HEN.DE">Henkel</option>
                    <option value="IFX.DE">Infineon</option>
                    <option value="MBG.DE">Mercedes Benz Group</option>
                    <option value="MRK.DE">Merck</option>
                    <option value="6MK.DE">Merck</option>
                    <option value="PAH3.DE">Porsche Automobil Holding SE</option>
                    <option value="QIA.DE">Qiagen NV</option>
                    <option value="RHM.DE">Rheinmetall</option>
                    <option value="RWE.DE">RWE</option>
                    <option value="SAP.DE">SAP</option>
                    <option value="SRT3.DE">Sartorius AG Vz</option>
                    <option value="SIE.DE">Siemens</option>
                    <option value="ENR.DE">Siemens</option>
                    <option value="SHL.DE">Siemens</option>
                    <option value="ENR.DE">Siemens Energy AG</option>
                    <option value="SHL.DE">Siemens Healthineers</option>
                    <option value="SY1.DE">Symrise AG</option>
                    <option value="VNA.DE">Vonovia</option>
                    <option value="ZAL.DE">Zalando SE</option>
                </optgroup>
            </select>
            <select name="timeframe" id="timeframe"
                    onChange={element => onChangeTimeframeHandler(element.target.value)}>
                <option value="1d" selected="selected">Day Of Year</option>
                <option value="1wk">Week Of Year</option>
                <option value="1mo">Month Of Year</option>
            </select>
            <Button variant="contained" onClick={onAnalysisHandler}>Analyze</Button>
        </div>
    );
}

export {SeasonalAnalysisBar};
