import React from "react";
import {withAuth0} from "@auth0/auth0-react";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {Spinner} from "react-bootstrap";

class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jsonData: [],
            isPortfolioBacktestResultLoading: false,
            isPortfolioBacktestResultAvailable: false
        };
    }

    componentDidMount() {
        this.getUserPortfolio();
    }

    async getUserPortfolio() {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/portfolio/bookmark?email=' + this.props.auth0.user.email + '&token=' + process.env.REACT_APP_API_TOKEN);
            const jsonData = await response.json();
            this.setState({jsonData: jsonData});
        } catch (error) {
            console.error('Error while getting portfolio:', error);
        }
    }

    handleSendClick = () => {
        const jsonToSend = JSON.stringify({Components: this.state.jsonData});

        this.setState({isPortfolioBacktestResultLoading: true});
        this.setState({isPortfolioBacktestResultAvailable: false});
        fetch(process.env.REACT_APP_API_URL + '/portfolio/performance', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: jsonToSend
        })
            .then(response => response.json())
            .then(data => {
                this.setState({isPortfolioBacktestResultLoading: false});
                this.setState({isPortfolioBacktestResultAvailable: true});
                this.updatePortfolioPerformanceChart("portfoliochartdiv", data.portfolio.data);
            })
            .catch(error => {
                console.error('Error while calculating portfolio performance:', error);
            });
    };

    updatePortfolioPerformanceChart = (chartDiv, portfolioData) => {

        portfolioData.forEach(function (item) {
            item.NegativeDrawdownPct = (parseFloat(item.DrawdownPct) * -100.0).toFixed(2);
        });

        am4core.useTheme(am4themes_animated);

        let chart = am4core.create(chartDiv, am4charts.XYChart);

        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "selectX";
        chart.scrollbarX = new am4core.Scrollbar();
        chart.leftAxesContainer.layout = "vertical";

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        dateAxis.title.text = "Date";
        valueAxis.title.text = "Profit";
        valueAxis.zIndex = 1;
        valueAxis.height = am4core.percent(80);

        let series = chart.series.push(new am4charts.LineSeries());
        series.name = "Performance";
        series.data = portfolioData;
        series.dataFields.dateX = "Date";
        series.dataFields.valueY = "Equity";
        series.tooltipText = 'Profit {valueY}';
        series.strokeWidth = 2;
        series.minBulletDistance = 10;
        series.bullets.push(new am4charts.CircleBullet());

        var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.tooltip.disabled = true;
        valueAxis2.height = am4core.percent(20);
        valueAxis2.zIndex = 3
        valueAxis2.marginTop = 30;
        valueAxis2.renderer.gridContainer.background.fill = am4core.color("#000000");
        valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;

        let seriesDD = chart.series.push(new am4charts.LineSeries());
        seriesDD.yAxis = valueAxis2;
        seriesDD.name = "Drawdown";
        seriesDD.data = portfolioData;
        seriesDD.dataFields.dateX = "Date";
        seriesDD.dataFields.valueY = "NegativeDrawdownPct";
        seriesDD.tooltipText = 'Drawdown {valueY}%';
        seriesDD.stacked = true;
        seriesDD.strokeWidth = 2;
        seriesDD.minBulletDistance = 10;
        seriesDD.stroke = am4core.color("#ff0000");
        var bullet = seriesDD.bullets.push(new am4charts.CircleBullet());
        bullet.fill = am4core.color("#ff0000");
    }

    render() {
        return (
            <>
                <div className="row">
                    <h1>Portfolio</h1>
                </div>
                <div className="row">
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{display: 'none'}}>id</TableCell>
                                    <TableCell style={{display: 'none'}}>id_user</TableCell>
                                    <TableCell>Ticker</TableCell>
                                    <TableCell>Strategy</TableCell>
                                    <TableCell>Backtest From</TableCell>
                                    <TableCell>Backtest To</TableCell>
                                    <TableCell>Entry</TableCell>
                                    <TableCell>Exit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.jsonData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{display: 'none'}}>{row.id}</TableCell>
                                        <TableCell style={{display: 'none'}}>{row.id_user}</TableCell>
                                        <TableCell>{row.ticker}</TableCell>
                                        <TableCell>{row.period === "1d" ? "DayOfYear" : row.period === "1wk" ? "WeekOfYear" : "MonthOfYear"}</TableCell>
                                        <TableCell>{row.history_start_date}</TableCell>
                                        <TableCell>{row.history_stop_date}</TableCell>
                                        <TableCell>{row.entry_point}</TableCell>
                                        <TableCell>{row.exit_point}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="secondary"
                                                    onClick={() => {
                                                        fetch(process.env.REACT_APP_API_URL + '/portfolio/bookmark', {
                                                                method: 'DELETE',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify({
                                                                    id: row.id,
                                                                    user_id: row.id_user
                                                                })
                                                            }
                                                        )
                                                            .then(response => {
                                                                if (response.ok) {
                                                                    this.setState({isPortfolioBacktestResultAvailable: false});
                                                                    this.getUserPortfolio();
                                                                }
                                                            });
                                                    }}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <br/>
                <div className="row">
                    <Button variant="contained" onClick={this.handleSendClick}>Portfolio Performance</Button>
                </div>
                <br/>
                {
                    this.state.isPortfolioBacktestResultLoading ?
                        <div className="alert alert-success" role="alert">
                            <center><Spinner animation="border"/></center>
                        </div>
                        :
                        this.state.isPortfolioBacktestResultAvailable ?
                            <div className="row">
                                <div id="portfoliochartdiv" style={{width: "100%", height: "500px"}}/>
                            </div>
                            :
                            <div/>
                }
            </>);
    }
}

export default withAuth0(Portfolio);

