import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function updateSeasonalChart(chartDiv) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(chartDiv, am4charts.XYChart);

    chart.legend = new am4charts.Legend();
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "selectX";

    chart.cursor.events.on("selectended", function (ev) {
        var range = ev.target.xRange;
        if (range) {
            var axis = ev.target.chart.xAxes.getIndex(0);
            var from = axis.getPositionLabel(axis.toAxisPosition(range.start)).split(" ")[1];
            var to = axis.getPositionLabel(axis.toAxisPosition(range.end)).split(" ")[1];
            this.backtestSeasonality(this.state.ticker, this.state.timeframe, from, to);
        }
    }.bind(this));

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    if (this.state.timeframe === "1d") {
        dateAxis.title.text = "Day Of Year";
    } else if (this.state.timeframe === "1wk") {
        dateAxis.title.text = "Week Of Year";
    } else {
        dateAxis.title.text = "Month Of Year";
    }

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (this.state.timeframe === "1d") {
        valueAxis.title.text = "Cumulative Profit (%)";
    } else {
        valueAxis.title.text = "Average Profit (%)";
    }

    // updateChartWithFullHistorySeries.call(this, chart);
    updateChartWithMultipleSeries.call(this, chart);
}

function updateChartWithMultipleSeries(chart) {
    // need to use this two variables to avoid problem accessing "this" inside fetch
    let timeframe = this.state.timeframe;
    let ticker = this.state.ticker;
    fetch(process.env.REACT_APP_API_URL + '/seasonal?ticker=' + ticker + '&period=' + timeframe)
        .then(response => response.json())
        .then(data => {
            data.seasonal.forEach(function (season) {
                let series;
                if (timeframe === "1d") {
                    series = chart.series.push(new am4charts.LineSeries());
                } else {
                    series = chart.series.push(new am4charts.ColumnSeries());
                }
                series.name = season.year + "Y";
                series.data = season.analysis.data;
                series.dataFields.dateX = "GroupedBy";
                series.dataFields.valueY = "Variation";
                // if (timeframe === "1d") {
                //     series.tooltipText = `Cumulative Profit ${season.year}Y {valueY}%`;
                // } else {
                //     series.tooltipText = `Average Profit ${season.year}Y {valueY}%`;
                // }
                series.strokeWidth = 2;
                series.minBulletDistance = 10;

                if (timeframe === "1d") {
                    series.bullets.push(new am4charts.CircleBullet());
                } else {
                    // Define colors for positive and negative bars
                    series.columns.template.adapter.add("fill", function (fill, target) {
                        if (target.dataItem.valueY > 0) {
                            return am4core.color("#17a2b8"); // Green for positive values
                        } else {
                            return am4core.color("#c41111"); // Red for negative values
                        }
                    });
                }
            });
        });
}

function updateChartWithFullHistorySeries(chart) {
    fetch(process.env.REACT_APP_API_URL + '/seasonal?ticker=' + this.state.ticker + '&period=' + this.state.timeframe)
        .then(response => response.json())
        .then(data => {
            // Data Arrays
            chart.data = data.seasonal.data;

            // Create the Equity Line Series
            var equityLine;

            if (this.state.timeframe === "1d") {
                equityLine = chart.series.push(new am4charts.LineSeries());
            } else {
                equityLine = chart.series.push(new am4charts.ColumnSeries());
            }
            equityLine.name = "Seasonality";
            equityLine.dataFields.dateX = "GroupedBy";
            equityLine.dataFields.valueY = "Variation";
            // if (this.state.timeframe === "1d") {
            //     equityLine.tooltipText = "Cumulative Profit {valueY}%";
            // } else {
            //     equityLine.tooltipText = "Average Profit {valueY}%";
            // }
            equityLine.strokeWidth = 2;
            equityLine.minBulletDistance = 10;
            if (this.state.timeframe === "1d") {
                equityLine.bullets.push(new am4charts.CircleBullet());
            } else {
                // Define colors for positive and negative bars
                equityLine.columns.template.adapter.add("fill", function (fill, target) {
                    if (target.dataItem.valueY > 0) {
                        return am4core.color("#17a2b8"); // Green for positive values
                    } else {
                        return am4core.color("#c41111"); // Red for negative values
                    }
                });
            }
        });
}

export {updateSeasonalChart, updateChartWithMultipleSeries, updateChartWithFullHistorySeries};