import React from "react";

class Footer extends React.Component {
    render() {
        return (
            <footer className="bg-light p-3 text-center">
                {/*<div className="logo" />*/}
                <p>
                    <a target="_blank" rel="noopener noreferrer" href="#">
                        © 2023 | All Rights Reserved
                    </a>
                </p>
            </footer>
        );
    }
}

export default Footer;
