import React from "react";

import {withAuth0} from "@auth0/auth0-react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {SeasonalAnalysisBar} from "../function/seasonal-analysis-bar";
import {updateSeasonalChart} from "../function/update-seasonal-chart";
import {Accordion, AccordionDetails, AccordionSummary, Button, Typography} from "@material-ui/core";
import {updateBacktestTradeChart} from "../function/update-backtest-trades-chart";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ticker: "",
            timeframe: "",
            entryPoint: 0,
            exitPoint: 0,
            winRatePct: "-",
            profitFactor: 0,
            expectancyPct: 0,
            returnPct: 0,
            returnAnnualized: 0,
            sharpRatio: 0,
            bhPct: 0,
            bestTrade: 0,
            worstTrade: 0,
            avgTrade: 0,
            maxDrawdown: 0,
            avgDrawdown: 0,
            isBacktestResultLoading: false,
            isBacktestResultAvailable: false
        };
    }

    backtestSeasonality(ticker, timeframe, entrypoint, exitpoint) {
        function updateStateData(data) {
            this.setState({ticker: ticker});
            this.setState({timeframe: timeframe});
            this.setState({entryPoint: entrypoint});
            this.setState({exitPoint: exitpoint});
            this.setState({winRatePct: data.WinRatePct.toFixed(0)});
            this.setState({profitFactor: data.ProfitFactor.toFixed(2)});
            this.setState({expectancyPct: data.ExpectancyPct.toFixed(2)});
            this.setState({returnPct: data.ReturnPct.toFixed(2)});
            this.setState({returnAnnualized: data.ReturnAnnualizedPct.toFixed(2)});
            this.setState({sharpRatio: data.SharpeRatio.toFixed(2)});
            this.setState({bhPct: data.BuyAndHoldPct.toFixed(2)});
            this.setState({bestTrade: data.BestTradePct.toFixed(2)});
            this.setState({worstTrade: data.WorstTradePct.toFixed(2)});
            this.setState({avgTrade: data.AvgTradePct.toFixed(2)});
            this.setState({maxDrawdown: data.MaxDrawdownPct.toFixed(2)});
            this.setState({avgDrawdown: data.AvgDrawdownPct.toFixed(2)});
            this.setState({isBacktestResultAvailable: true});
            this.setState({isBacktestResultLoading: false});
        }

        function updateBacktestChart(data) {
            am4core.useTheme(am4themes_animated);

            var chart = am4core.create("backtestchartdiv", am4charts.XYChart);
            chart.legend = new am4charts.Legend();
            chart.cursor = new am4charts.XYCursor();
            chart.scrollbarX = new am4core.Scrollbar();

            var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.title.text = "Date";
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Equity Line";

            dateAxis.tooltipDateFormat = "dd/MM/yyyy";

            // Data Arrays
            chart.data = data.equity.data;

            // Create the Equity Line Series
            var equityLine = chart.series.push(new am4charts.LineSeries());
            equityLine.name = "Equity Line";
            equityLine.dataFields.dateX = "Date";
            equityLine.dataFields.valueY = "Equity";
            equityLine.tooltipText = "Equity {valueY}€";
            equityLine.strokeWidth = 2;
            equityLine.minBulletDistance = 10;
            equityLine.bullets.push(new am4charts.CircleBullet());
        }

        function updateBacktestTradesTable(data) {
            var table = document.getElementById("backtestresulttrades");
            if (!table.querySelector('tbody')) {
                table.appendChild(document.createElement('tbody'));
            }
            var tbody = table.querySelector('tbody');
            while (tbody.firstChild) {
                tbody.removeChild(tbody.firstChild);
            }

            data.forEach(function (trade) {
                var row = tbody.insertRow(0);
                var entryDate = row.insertCell(0);
                var entryPrice = row.insertCell(1);
                var exitDate = row.insertCell(2);
                var exitPrice = row.insertCell(3);
                var profitPct = row.insertCell(4);

                entryDate.innerHTML = trade.EntryTime.split("T")[0];
                entryPrice.innerHTML = trade.EntryPrice;
                exitDate.innerHTML = trade.ExitTime.split("T")[0];
                exitPrice.innerHTML = trade.ExitPrice;
                profitPct.innerHTML = (((trade.ExitPrice - trade.EntryPrice) / trade.EntryPrice) * 100).toFixed(2);
            });

        }

        this.setState({isBacktestResultAvailable: false});
        this.setState({isBacktestResultLoading: true});

        fetch(process.env.REACT_APP_API_URL + '/backtest?ticker=' + ticker + '&start=1990-01-01&end=2024-01-01&period=' + timeframe + '&entry_point=' + entrypoint + '&exit_point=' + exitpoint)
            .then(response => response.json())
            .then(data => {
                updateStateData.call(this, data);
                updateBacktestChart(data);
                updateBacktestTradeChart("backtesttradeschartdiv", data.trades.data);
                updateBacktestTradesTable(data.trades.data);
            });
    }

    calculateSeasonality() {
        if (this.state.isBacktestResultLoading) {
            window.alert("Please wait until the backtest is completed.");
        } else {
            this.setState({isBacktestResultAvailable: false});
            updateSeasonalChart.call(this, "seasonalchartdiv");
        }
    }

    onChangeTimeframe(value) {
        this.setState({timeframe: value});
    }

    onChangeTicker(value) {
        this.setState({ticker: value});
    }

    onEntryChange(event) {
        // this.setState({entryPoint: value});
        // this.backtestSeasonality(this.state.ticker, this.state.timeframe, value, this.state.exitPoint)
        // console.log(event.target.value);
    }

    onExitChange(event) {
        // this.setState({exitPoint: value});
        // this.backtestSeasonality(this.state.ticker, this.state.timeframe, this.state.entryPoint, value)
        // console.log(event.target.value);

    }

    componentDidMount() {
        this.setState({timeframe: document.getElementById("timeframe").value});
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    renderTooltip(text) {
        return (<Tooltip>{text}</Tooltip>);
    }

    renderTextWithTooltip(text, tooltip) {
        return <OverlayTrigger
            placement="top"
            overlay={this.renderTooltip(tooltip)}
        >
            <b>{text}</b>
        </OverlayTrigger>;
    }

    getValueIfAvailable(value, altSymbol) {
        return this.state.isBacktestResultAvailable ?
            value :
            <OverlayTrigger
                placement="top"
                overlay={this.renderTooltip("Data not available. Please select a ticker and a strategy and click on Analyze button. Then highlight the chart to see the backtest results.")}
            >
                <button>-</button>
            </OverlayTrigger>;
    }

    getTimeframeLabel() {
        return this.state.timeframe === "1d" ? "Day" : this.state.timeframe === "1wk" ? "Week" : "Month";
    }

    getBacktestResultSummary() {
        return <div className="card text-center">
            <div className="alert alert-success" role="alert">
                <b>Seasonal Window Summary</b>
            </div>
            <div className="card-body">
                {this.getBacktestResultSummaryInterval()}
                <div className="row">
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Win[%]: ", "Percentage of winning trades")}
                                        {this.getValueIfAvailable(this.state.winRatePct, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Best Trade[%]: ", "Best winning profit")}
                                        {this.getValueIfAvailable(this.state.bestTrade, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Avg. Trade[%]: ", "Average trade profit. It is calculated as the ratio of total profit to total trades")}
                                        {this.getValueIfAvailable(this.state.avgTrade, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Profit Factor: ", "Profitability. It is calculated as the ratio of total  gain to total loss. Value > 1.5 is preferable.")}
                                        {this.getValueIfAvailable(this.state.profitFactor, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Sharpe Ratio[%]: ", "Risk-adjusted return. It is calculated as the ratio of total return to total risk. Higher is better.")}
                                        {this.getValueIfAvailable(this.state.sharpRatio, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Expectancy[%]: ", "Estimate the average amount a trader can expect to gain (or lose) for each euro risked.")}
                                        {this.getValueIfAvailable(this.state.expectancyPct, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Return[%]: ", "Return on investment")}
                                        {this.getValueIfAvailable(this.state.returnPct, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Return Annualized[%]: ", "Annualized return on investment")}
                                        {this.getValueIfAvailable(this.state.returnAnnualized, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Buy & Hold Return[%]: ", "Return on investment if you buy and hold the instrument")}
                                        {this.getValueIfAvailable(this.state.bhPct, "-")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item" defaultValue="-">
                                        {this.renderTextWithTooltip("Lose[%]: ", "Percentace of losing trades")}
                                        {this.getValueIfAvailable(100 - this.state.winRatePct, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Worst Trade[%]: ", "Best losing profit")}
                                        {this.getValueIfAvailable(this.state.worstTrade, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Avg. DD[%]: ", "Average drawdown. It is calculated as the ratio of total drawdown to total trades. Smaller is better.")}
                                        {this.getValueIfAvailable(this.state.avgDrawdown, "-")}
                                    </li>
                                    <li className="list-group-item">
                                        {this.renderTextWithTooltip("Max DD[%]: ", "Maximum drawdown. It is calculated as the maximum loss from a peak to a trough of a portfolio, before a new peak is attained. Smaller is better.")}
                                        {this.getValueIfAvailable(this.state.maxDrawdown, "-")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    onAddPortfolioBookmark = () => {
        let today = new Date();
        let endHistory = today.toISOString().slice(0, 10);
        today.setFullYear(today.getFullYear() - 15);
        let startHistory = today.toISOString().slice(0, 10);

        const jsonToSend = JSON.stringify({
            email: this.props.auth0.user.email,
            ticker: this.state.ticker,
            period: this.state.timeframe,
            entry: this.state.entryPoint,
            exit: this.state.exitPoint,
            history_start_date: startHistory,
            history_stop_date: endHistory
        });

        fetch(process.env.REACT_APP_API_URL + '/portfolio/bookmark', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: jsonToSend
        })
            .then(response => response.json())
            .then(data => {
                window.alert("Portfolio updated successfully.");
            })
            .catch(error => {
                window.alert("Error while adding seasonal to portfolio. Please try again.");
            });
    }

    getBacktestResultSummaryInterval() {
        return <>
            <Button variant="contained"
                    onClick={this.onAddPortfolioBookmark}
                    disabled={!this.state.isBacktestResultAvailable}>
                Add To Portfolio
            </Button>
            <br/>
            <b>
                Entry {this.getTimeframeLabel()} Of Year:
            </b>
            {
                this.state.isBacktestResultAvailable ?
                    <input type="number" min="0" step="1" defaultValue={this.state.entryPoint}
                           onChange={this.onEntryChange()}/> :
                    this.getValueIfAvailable(this.state.entryPoint, "-")
            }
            <br/>
            <b>
                Exit {this.getTimeframeLabel()} Of Year:
            </b>
            {
                this.state.isBacktestResultAvailable ?
                    <input type="number" min="0" step="1" defaultValue={this.state.exitPoint}
                           onChange={this.onExitChange}/> :
                    this.getValueIfAvailable(this.state.exitPoint, "-")
            }
            <br/>
            <b>
                {this.getTimeframeLabel()} in Seasonal Window:
            </b>
            {this.getValueIfAvailable(Math.abs(this.state.exitPoint - this.state.entryPoint), "-")}
            <br/>
        </>;
    }

    getBacktestResultChart() {
        return <>
            {
                this.state.isBacktestResultLoading ?
                    <div className="alert alert-success" role="alert">
                        <center><Spinner animation="border"/></center>
                    </div>
                    :
                    this.state.isBacktestResultAvailable ?
                        <div>
                            <div className="alert alert-success" role="alert">
                                <b>Seasonal Window Equity Line</b>
                            </div>
                            <div id="backtestchartdiv"
                                 style={{width: "100%", height: "500px"}}></div>
                        </div>
                        :
                        <div/>
            }
        </>;
    }

    getBacktestResultData() {
        return <>
            <div className="card">
                {
                    this.state.isBacktestResultAvailable ?
                        <div>
                            <div className="alert alert-success" role="alert">
                                <b>Seasonal Window Historic Performance</b>
                            </div>
                            <div id="backtesttradeschartdiv" style={{width: "100%", height: "500px"}}></div>
                            {this.getTradeListTable()}
                        </div>
                        :
                        <div/>
                }
            </div>
        </>;
    }

    getTradeListTable() {
        return <div>
            <Accordion>
                <AccordionSummary>
                    <Typography>Trade List</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <table id="backtestresulttrades" className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Entry Date</th>
                            <th scope="col">Entry Price</th>
                            <th scope="col">Exit Date</th>
                            <th scope="col">Exit Price</th>
                            <th scope="col">Profit [%]</th>
                        </tr>
                        </thead>
                    </table>
                </AccordionDetails>
            </Accordion>
        </div>;
    }

    render() {
        return (
            <>
                <div className="alert alert-info" role="alert">
                    <b>Demo Version</b>: Only FTSEMIB and DAX tikers are available for backtesting.
                </div>
                <br/>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="alert alert-success" role="alert">
                            <b>Seasonal Analysis</b>
                            <p>To calculate the Seasonal Chart search the Ticker and select the Strategy from the menu
                                below
                                and than click Analyze.
                                <br/>To Backtest the Seasonal Window directly highlight the corresponding window in
                                the Seasonal Chart.</p>
                        </div>
                        <SeasonalAnalysisBar
                            onChangeTickerHandler={this.onChangeTicker.bind(this)}
                            onChangeTimeframeHandler={this.onChangeTimeframe.bind(this)}
                            onAnalysisHandler={this.calculateSeasonality.bind(this)}
                        />
                        <div id="seasonalchartdiv" style={{width: "100%", height: "500px"}}></div>
                    </div>
                    <div className="col-6 col-md-4">
                        {this.getBacktestResultSummary()}
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-12 col-md-8">
                        {this.getBacktestResultChart()}
                    </div>
                    <div className="col-6 col-md-4">
                        {this.getBacktestResultData()}
                    </div>
                </div>
            </>
        );
    };
}

export default withAuth0(Dashboard);
