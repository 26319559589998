import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill, faMousePointer, faPercentage, faUserClock} from "@fortawesome/free-solid-svg-icons";

class HomeContent extends React.Component {
    render() {
        return (
            <div className="next-steps">
                <h2 className="my-5 text-center">Cosa posso fare con Seasonally?</h2>

                <div className="row">
                    <div className="col-md-5 mb-4">
                        <h6 className="mb-3">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="#"
                            >
                                <FontAwesomeIcon icon={faMousePointer} className="mr-2"/>
                                Analizzare gli andamenti stagionali in soli 2 click
                            </a>
                        </h6>
                        <p>
                            Utilizzate la nostra dashboard per identificare opportunità ad alta probabilità in
                            un paio di clic. Come risultato avrete il rendimento storico di un'operazione che state
                            cercando di intraprendere.
                        </p>
                    </div>

                    <div className="col-md"/>

                    <div className="col-md-5 mb-4">
                        <h6 className="mb-3">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="#"
                            >
                                <FontAwesomeIcon icon={faUserClock} className="mr-2"/>
                                Ottimizzare il timing delle operazioni di trading
                            </a>
                        </h6>
                        <p>
                            Le tendenze stagionali creano "finestre" di tempo ad alta probabilità in cui uno strumento
                            si muoverà prevedibilmente al rialzo o al ribasso con una precisione fino al 95%. Azzeccare
                            i tempi giusti può fare la differenza tra una facile vincita e una difficile perdita.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-5 mb-4">
                        <h6 className="mb-3">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="#"
                            >
                                <FontAwesomeIcon icon={faPercentage} className="mr-2"/>
                                Aumenta la probabilità di successo
                            </a>
                        </h6>
                        <p>
                            Iniziate la vostra strategia di trading con un'operazione stagionale ad alta probabilità.
                            Prima di rischiare il vostro denaro, utilizzate il nostro algoritmo proprietario per
                            verificare la tendenza e confermare la vostra decisione. Aggiungete alla vostra analisi
                            fondamentale e tecnica il tasso di vincita di un pattern stagionale ad alta probabilità!
                        </p>
                    </div>

                    <div className="col-md"/>

                    <div className="col-md-5 mb-4">
                        <h6 className="mb-3">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="#"
                            >
                                <FontAwesomeIcon icon={faMoneyBill} className="mr-2"/>
                                Identificare i modelli stagionali forti e deboli
                            </a>
                        </h6>
                        <p>
                            Trova eccellenti opportunità di investimento in pochi secondi.
                            Esaminare gli strumenti per individuare i prossimi modelli stagionali redditizi ed
                            identificare rapidamente quali strumenti hanno il miglior potenziale.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeContent;
