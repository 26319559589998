import React from "react";

class Hero extends React.Component {
    logo = "https://sharek.it/";

    render() {
        return (
            <div className="text-center hero">
                {/*<img*/}
                {/*    className="mb-3 app-logo"*/}
                {/*    src={this.logo}*/}
                {/*    alt="React logo"*/}
                {/*    width="120"*/}
                {/*/>*/}
                <h1 className="mb-4">Seasonally</h1>
                <p className="lead"> Mettete le probabilità a vostro favore.</p>
            </div>
        );
    }
}

export default Hero;
